import React from "react";
import expiredlinksvg from "../Assets/expiredlink.svg";
import "./ExpiredLink.css";

const ExpiredLink = () => {
    return (
        <div>
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="expiredlinksvg">
                    <img
                        className="exp"
                        src={expiredlinksvg}
                        alt="expired link"
                    />
                </div>
            </div>
        </div>
    );
};

export default ExpiredLink;

import React, { useState, useEffect } from "react";
import NavbarAdmin from "../../Components/NavbarAdmin";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useNavigate, useParams } from "react-router-dom";
import Directory from "../../baseDirectory";
import axios from "axios";
import baseUrl from "../../baseUrl";
import jwt_decode from "jwt-decode";
import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Stack } from "@mui/material";
import Swal from "sweetalert2";

const DetailEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [namaSekolah, setNamaSekolah] = useState("");
  const [namaEvent, setNamaEvent] = useState("");
  const [tanggalPelaksanaan, settanggalPelaksanaan] = useState("");
  const [dataPeserta, setdataPeserta] = useState([]);
  const [hapus, setHapus] = useState();

  const { eventId } = useParams();

  const tokenJwt = useSelector((state) => state.admTokenReducer.data.token);
  const expire = jwt_decode(tokenJwt).exp;

  const columns = [
    "No",
    {
      name: "NISN",
      label: "NISN",
      options: {
        filter: false,
      },
    },
    {
      name: "NamaLengkap",
      label: "Nama Lengkap",
    },
    {
      name: "TanggalLahir",
      label: "Tanggal Lahir",
      options: {
        customBodyRender: (value) => {
          const formattedDate = new Date(value).toLocaleDateString("id-ID", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          });
          return <div>{formattedDate}</div>;
        },
      },
    },
    "Email",
    {
      name: "PhoneNumber",
      label: "No Handphone",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <div>0{value}</div>;
        },
      },
    },
    {
      name: "Kelas",
      label: "Kelas",
    },
    {
      name: "Jenjang",
      label: "Jenjang",
    },
    {
      name: "Hasil",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {!value.tipe ? (
                <div>{value.mbti}</div>
              ) : (
                <div
                  className="badge bg-danger"
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => {
                    navigate(`/${Directory}/hasil_mbti`, {
                      state: {
                        idPeserta: value._id,
                      },
                    });
                  }}
                >
                  {value.tipe}
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "Aksi",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction={"row"} spacing={1}>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#ABABAB",
                  color: "black",
                }}
                onClick={() => {
                  navigate(
                    `/${Directory}/edit_peserta/${value.pesertaId}/${value.eventId}`
                  );
                }}
              >
                <EditIcon />
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleDeletePeserta(value.pesertaId);
                }}
              >
                <DeleteIcon />
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  const handleDownloadResult = async () => {
    try {
      setIsLoadingDownload(true)
      const response = await axios.get(`${baseUrl}/downloadResult/${eventId}`, {
        responseType: "blob", // Important to ensure the response is a blob
      })

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "hasil_mbti.xlsx"); // Set the filename
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link); // Clean up the link element
      setIsLoadingDownload(false)
    } catch (error) {
      setIsLoadingDownload(false)
      console.log(error)
    }
    return false
  }
  const options = {
    search: true,
    download: false,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "40%",
    pagination: true,
    rowHover: true,
    tableBodyMaxHeight: "500px",
    selectableRows: "none",

  };
  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await axios.get(`${baseUrl}/accessToken`);
        dispatch({
          type: GET_TOKEN,
          payload: {
            token: res.data.adm_access_token,
          },
        });
      } catch (error) {
        axios.get(`${baseUrl}/logout`).then(() => { });
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
      }
    };
    getToken();
  }, [dispatch]);

  useEffect(() => {
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(
      async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(`${baseUrl}/accessToken`);
          config.headers.Authorization = response.data.adm_access_token;
          dispatch({
            type: GET_TOKEN,
            payload: {
              token: response.data.adm_access_token,
            },
          });
        }
        return config;
      },
      (error) => {
        axiosJWT.get(`${baseUrl}/logout`).then(() => { });
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
        return Promise.reject(error);
      }
    );
    const getPesertaByEventId = async () => {
      try {
        let { data } = await axiosJWT.get(`${baseUrl}/event/${eventId}`, {
          headers: {
            Authorization: tokenJwt,
          },
        });
        setNamaEvent(data.nama_event);
        setNamaSekolah(data.nama_sekolah);
        settanggalPelaksanaan(
          new Date(data.tanggal_pelaksanaan).toLocaleDateString("en-GB")
        );
        ConvertDataPeserta(data.peserta);
      } catch (error) {
        console.log(error);
        axiosJWT.get(`${baseUrl}/logout`).then(() => { });
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
      }
    };
    getPesertaByEventId();
  }, [hapus]);

  const ConvertDataPeserta = (data) => {

    const result = [];
    let obj = {};
    data.forEach((element, index) => {
      obj.No = index + 1;
      obj.NISN = element.nisn;
      obj.PhoneNumber = element.no_hp;
      obj.NamaLengkap = element.nama;
      obj.TanggalLahir = element.tanggal_lahir;
      obj.Email = element.email;
      obj.NamaEvent = element.nama_event;
      obj.PublishHasil = element.publish_hasil ? "Ya" : "Tidak";
      obj.Kelas = element.kelas;
      obj.Jenjang = element.jenjang_pendidikan;
      obj.Hasil = {
        _id: element._id,
        mbti: element.hasil_mbti,
        tipe: element?.hasil_mbti?.tipe,
      };
      obj.Aksi = {
        pesertaId: element._id,
        eventId: element.eventId,
      };
      result.push(obj);
      obj = {};
    });
    setdataPeserta(result);
    setIsLoading(false);
  };

  const handleDeletePeserta = async (pesertaId) => {
    try {
      const result = await Swal.fire({
        title: "Anda Yakin?",
        text: "Anda tidak akan dapat mengembalikan ini!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      });
      if (result.isConfirmed) {
        const axiosJWT = axios.create();
        axiosJWT.interceptors.request.use(
          async (config) => {
            const currentDate = new Date();
            if (expire * 1000 < currentDate.getTime()) {
              const response = await axios.get(`${baseUrl}/accessToken`);
              config.headers.Authorization = response.data.adm_access_token;
              dispatch({
                type: GET_TOKEN,
                payload: {
                  token: response.data.adm_access_token,
                },
              });
            }
            return config;
          },
          (error) => {
            axiosJWT.get(`${baseUrl}/logout`).then(() => { });
            localStorage.removeItem("LoginSdm");
            dispatch({ type: ADMIN_LOGOUT });
            return Promise.reject(error);
          }
        );
        const res = await axiosJWT.delete(`${baseUrl}/peserta/${pesertaId}`, {
          headers: {
            Authorization: tokenJwt,
          },
        });
        setHapus(res.data);
        Swal.fire({
          title: "Terhapus!",
          text: "Data sudah terhapus.",
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <div>
      <NavbarAdmin />;
      {isLoading && (
        <div
          style={{
            height: "80vh",
            position: "relative",
            fontSize: "30px",
            fontWeight: "bolder",
          }}
        >
          <div className="text-center text-danger vertical-center">
            <div
              className="spinner-border"
              role="status"
              style={{
                width: "50px",
                height: "50px",
              }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <div>Loading...</div>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="container mt-5">
          <div className="text-start">
            <table>
              <thead>
                <tr>
                  <th>Nama Event</th>
                  <th style={{ padding: "4px" }}>
                    <span>:</span>
                  </th>
                  <td>{namaEvent}</td>
                </tr>
                <tr>
                  <th>Nama Sekolah</th>
                  <th style={{ padding: "4px" }}>
                    <span>:</span>
                  </th>
                  <td>{namaSekolah}</td>
                </tr>
                <tr>
                  <th>Tanggal Pelaksanaan</th>
                  <th style={{ padding: "4px" }}>
                    <span>:</span>
                  </th>
                  <td>{tanggalPelaksanaan}</td>
                </tr>
              </thead>
            </table>
            <hr />
          </div>
          <div className="text-end mt-3 mb-3">
            <button
              onClick={() => {
                navigate(`/${Directory}/add_peserta/${eventId}`);
              }}
              type="button"
              className="btn btn-danger"
            >
              Tambah Peserta
            </button>
            <button
              disabled={isLoadingDownload}
              onClick={handleDownloadResult}
              type="button"
              className="btn btn-danger ms-2"
            >
              {isLoadingDownload ? "Mengunduh Data ... " : "Download Hasil"}
            </button>
          </div>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                title={"Data Peserta"}
                data={dataPeserta}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>
        </div>
      )}
    </div>
  );
};

export default DetailEvent;

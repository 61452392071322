const Blank = () => {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
        >
            <h1 style={{ color: "grey" }}>Not Found | 404</h1>
        </div>
    );
};

export default Blank;

import React, { useState, useEffect } from "react";
import NavbarAdmin from "../../Components/NavbarAdmin";
import axios from "axios";
import { useLocation } from "react-router-dom";
import url from "../../baseUrl";
import "./HasilMbti.css";

import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
axios.defaults.withCredentials = true;

const HasilMbti = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [peserta, setPeserta] = useState([]);
    const [mbti, setMbti] = useState([]);
    const [karakter, setKarakter] = useState([]);
    const [saranProfesi, setSaranProfesi] = useState([]);

    const tokenJwt = useSelector(
        (state) => state.admTokenReducer.data.token
    );
    const expire = jwt_decode(tokenJwt).exp;


    useEffect(() => {
        const getToken = async () => {
            try {
                const res = await axios.get(`${url}/accessToken`);
                dispatch({
                    type: GET_TOKEN,
                    payload: {
                        token: res.data.adm_access_token,
                    },
                });
            } catch (error) {
                axios.get(`${url}/logout`).then(() => { });
                localStorage.removeItem("LoginSdm");
                dispatch({ type: ADMIN_LOGOUT });
            }
        };
        getToken();
    }, [dispatch]);

    useEffect(() => {
        const axiosJWT = axios.create();

        axiosJWT.interceptors.request.use(
            async (config) => {
                const currentDate = new Date();
                if (expire * 1000 < currentDate.getTime()) {
                    const response = await axios.get(
                        `${url}/accessToken`
                    );
                    config.headers.Authorization =
                        response.data.adm_access_token;
                    dispatch({
                        type: GET_TOKEN,
                        payload: {
                            token: response.data.adm_access_token,
                        },
                    });
                }
                return config;
            },
            (error) => {
                axios.get(`${url}/logout`).then(() => { });
                localStorage.removeItem("LoginSdm");
                dispatch({ type: ADMIN_LOGOUT });
                return Promise.reject(error);
            }
        );

        const getPesertaById = async () => {
            try {
                let res = await axiosJWT.get(
                    `${url}/getHasil/${location.state.idPeserta}`,
                    {
                        headers: {
                            Authorization: tokenJwt,
                        },
                    }
                );
                console.log(res.data)
                setPeserta(res.data);
                setMbti(res.data.hasil_mbti.deskripsi);
                setKarakter(res.data.hasil_mbti.deskripsi.karakter);
                setSaranProfesi(
                    res.data.hasil_mbti.deskripsi.saran_profesi
                );
            } catch (error) {
                console.log(error);
            }
        };
        getPesertaById();
    }, [location.state.idPeserta, dispatch, expire, tokenJwt]);

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (
            m < 0 ||
            (m === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }
        return age;
    }

    return (
        <>
            <NavbarAdmin />
            <div className="container-mbti">
                <div className="data-diri-MBTI-section">
                    <div id="data-diri-MBTI">
                        <ul>
                            <li>
                                <b>Nama</b>
                                <p>:</p> {peserta?.nama}
                            </li>

                            {/* <li>
                                <b>Umur</b>
                                <p>:</p>{" "}
                                {getAge(peserta?.TanggalLahir)}
                            </li> */}
                            <li>
                                <b>Jenis Tes</b>
                                <p>:</p> MBTI
                            </li>
                            <li>
                                <b>Tanggal Tes</b>
                                <p>:</p>{" "}
                                {new Date(
                                    peserta?.tanggal_tes
                                ).toLocaleDateString("en-GB")}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mbti">
                    <h1>{mbti?.tipe}</h1>
                    <h5>({mbti?.arti})</h5>
                </div>
                {/* <--------------karakter-------------> */}
                <div className="karakter">
                    <h1>Karakter</h1>
                    <ul>
                        {karakter.map((el, index) => {
                            return <li key={index}>{el}</li>;
                        })}
                    </ul>
                </div>

                {/* <-------------end of karakter----------------> */}

                <div className="saran-pekerjaan">
                    <div className="pekerjaan">
                        <h1>Saran Jurusan</h1>
                    </div>

                    <div className="list-pekerjaan">
                        <ul className=" d-flex flex-wrap justify-content-start">
                            {saranProfesi.map((el, index) => {
                                return (
                                    <li
                                        className="me-5 text-start"
                                        key={index}
                                    // style={{ width: "20%" }}
                                    >
                                        {el}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HasilMbti;

import { ADMIN_LOGIN, ADMIN_LOGOUT, CHECK_NISN } from "../../../Constanst";

const initialState = {
  isLogged: false,
  isNisn: false,
  namaUser: "",
  tanggalTes: "",
  nisn: "",
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOGIN:
      localStorage.setItem("LoginSdm", true);
      return {
        ...state,
        isLogged: true,
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        isLogged: false,
      };
    case CHECK_NISN:
      return {
        ...state,
        isNisn: action.payload.isNisn === false ? false : true,
        namaUser: action.payload.nama,
        tanggalTes: action.payload.tanggal_tes,
        nisn: action.payload.nisn,
      };
    default:
      return state;
  }
};

export default auth;

import React from "react";
import bglinknotyet from "../Assets/linknotyet.png";

import "./LinkBelumAda.css";
const LinkBelumAda = () => {
    return (
        <div>
            <div className="position-absolute top-50 start-50 translate-middle ">
                <div>
                    <img
                        className="bg-tesBelumTersedia"
                        src={bglinknotyet}
                        alt="link belum ada"
                    />
                </div>
                <div className="content-tesBelumTersedia">
                    <h2>Maaf Tes Psikologi Belum Tersedia</h2>
                </div>
            </div>
        </div>
    );
};

export default LinkBelumAda;

import React from "react";

import { useCSVDownloader } from "react-papaparse";

export default function CSVDownloader() {
  const { CSVDownloader } = useCSVDownloader();

  return (
    <CSVDownloader
      // type={Type.Button}
      filename={"format-import-peserta"}
      bom={true}
      config={{
        delimiter: ",",
      }}
      data={[
        {
          nisn: "1234567891",
          nama: "Rico kurnia",
          tanggal_lahir: "1999-06-12",
          no_hp: "0812345678",
          email: "coocmail@gmail.com",
          kelas: "10",
          jenjang_pendidikan: "SMA",
        },
      ]}
    >
      <div style={{ color: "red", cursor: "pointer", display: "inline" }}>
        template CSV
      </div>
    </CSVDownloader>
  );
}

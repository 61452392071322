import { combineReducers } from "redux";
import { RESET_STORE } from "../../Constanst";
import storage from "redux-persist/lib/storage";

import data from "./State";
import admTokenReducer from "./State/tokenState";
import auth from "./State/auth";
import checkNisn from "./State/checkNisn"

const rootReducer = combineReducers({
    data,
    admTokenReducer,
    auth,
    checkNisn
});
const reducers = (state, action) => {
    if (action.type === RESET_STORE) {
        // for all keys defined in your persistConfig(s)
        storage.removeItem("persist:rootSdm");
        localStorage.clear();
        // storage.removeItem('persist:otherKey')
        state = undefined;
    }
    return rootReducer(state, action);
};

export default reducers;

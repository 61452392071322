import { useEffect } from "react";
import Router from "./Routes";
import "./App.css";
import { GET_TOKEN, ADMIN_LOGOUT, RESET_STORE } from "./Redux/Constanst";
import { useDispatch, useSelector } from "react-redux";
import url from "./baseUrl";
import axios from "axios";
axios.defaults.withCredentials = true;

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    const firstLoginAdmin = localStorage.getItem("LoginSdm");

    if (firstLoginAdmin) {
      const getToken = async () => {
        try {
          const res = await axios.get(`${url}/accessToken`);
          dispatch({
            type: GET_TOKEN,
            payload: {
              token: res.data.adm_access_token,
            },
          });
          console.log(res);
        } catch (error) {
          axios.get(`${url}/logout`).then(() => {});
          localStorage.removeItem("LoginSdm");
          dispatch({ type: ADMIN_LOGOUT });
        }
      };
      getToken();
    }
  }, [auth.isLogged, dispatch]);

  useEffect(() => {
    const resetStore = () => async (dispatch) => {
      try {
        axios.get(`${url}/logout`).then(() => {});
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
        dispatch({ type: RESET_STORE });
      } catch (error) {
        console.log(error);
      }
    };
    if (!localStorage.APP_VERSION) {
      dispatch(resetStore());
      localStorage.setItem("APP_VERSION", process.env.REACT_APP_VERSION);
    }
    if (localStorage.APP_VERSION !== process.env.REACT_APP_VERSION) {
      dispatch(resetStore());
      localStorage.setItem("APP_VERSION", process.env.REACT_APP_VERSION);
    }
  }, [dispatch]);

  return (
    <div>
      <Router />
    </div>
  );
}

export default App;

import React, { useEffect, useState, useMemo } from "react";
import { Navbar } from "../../Components/Navbar";
import { useForm } from "react-hook-form";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import axios from "axios";
import url from "../../baseUrl";
import "./TesMbti.css";
import Directory from "../../baseDirectory";
import { useSelector } from "react-redux";

// const Completionist = () => <Navigate to={`/${Directory}/expired_link`} />;

const TesMbti = () => {
  const { nisn } = useSelector((state) => state.auth);
  const { kodeEvent } = useSelector((state) => state.checkNisn);

  const [error, setError] = useState(false);
  const [minutes, setMinutes] = useState(30);
  const { token } = useParams();
  const navigate = useNavigate();

  const [soalMbti1, setSoalMbti1] = useState([]);
  const [soalMbti2, setSoalMbti2] = useState([]);
  const [soalMbti3, setSoalMbti3] = useState([]);
  const [handleLoadBut, setHandleLoadBut] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [jawaban, setJawaban] = useState(() => {
    const savedJawaban = localStorage.getItem(`jawabanMbtiSiswa${token}`);
    return savedJawaban ? JSON.parse(savedJawaban) : {};
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: jawaban });

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      try {
        handleSubmit(onSubmit)();
      } catch (error) {
        throw error;
      }
    } else {
      // Render a countdown
      return (
        <span
          className="fixed-top text-center"
          style={{
            margin: "100px auto",
            fontSize: "20px",
            backgroundColor: "gray",
            borderRadius: "5px",
            zIndex: "1000",
            display: "inline-block",
            width: "100px",
          }}
        >
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  const countDown = useMemo(() => {
    return (
      <Countdown date={Date.now() + minutes * 60000} renderer={renderer} />
    );
  }, [minutes]);

  const onSubmit = async (data) => {
    try {
      setHandleLoadBut(true);
      const result = {
        bagian1: {
          kolom1: [],
          kolom2: [],
          kolom3: [],
          kolom4: [],
        },
        bagian2: {
          kolom1: [],
          kolom2: [],
          kolom3: [],
          kolom4: [],
        },
        bagian3: {
          kolom1: [],
          kolom2: [],
          kolom3: [],
          kolom4: [],
        },
      };
      for (const key in data) {
        if (key.match("bagian1")) {
          if (key.match("tipe1")) {
            result.bagian1.kolom1.push(data[key]);
          } else if (key.match("tipe2")) {
            result.bagian1.kolom2.push(data[key]);
          } else if (key.match("tipe3")) {
            result.bagian1.kolom3.push(data[key]);
          } else if (key.match("tipe4")) {
            result.bagian1.kolom4.push(data[key]);
          }
        } else if (key.match("bagian2")) {
          if (key.match("tipe1")) {
            result.bagian2.kolom1.push(data[key]);
          } else if (key.match("tipe2")) {
            result.bagian2.kolom2.push(data[key]);
          } else if (key.match("tipe3")) {
            result.bagian2.kolom3.push(data[key]);
          } else if (key.match("tipe4")) {
            result.bagian2.kolom4.push(data[key]);
          }
        } else if (key.match("bagian3")) {
          if (key.match("tipe1")) {
            result.bagian3.kolom1.push(data[key]);
          } else if (key.match("tipe2")) {
            result.bagian3.kolom2.push(data[key]);
          } else if (key.match("tipe3")) {
            result.bagian3.kolom3.push(data[key]);
          } else if (key.match("tipe4")) {
            result.bagian3.kolom4.push(data[key]);
          }
        }
      }
      await axios.post(`${url}/submitMbti`, {
        ...result,
        nisn: nisn,
        kodeEvent: kodeEvent,
      });
      await axios.get(`${url}/sendEmailMbti/${nisn}/${kodeEvent}`);
      // dispatch(CheckNisn(undefined, undefined, setError, true));
      localStorage.removeItem(`jawabanMbtiSiswa${token}`);
      setHandleLoadBut(false);
      navigate(`/${Directory}/finish_submit_test/MBTI`);
    } catch (error) {
      setHandleLoadBut(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const updateTime = async () => {
      try {
        await axios.put(`${url}/updateTime`, {
          nisn: nisn,
          kodeEvent: kodeEvent,
        });
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };
    const getPesertaById = async () => {
      try {
        const peserta = await axios.get(
          `${url}/getPesertaByIdUser/${nisn}/${kodeEvent}`
        );
        if (peserta.data.status === "expired") {
          navigate(`/${Directory}/expired_link`);
        } else if (peserta.data.status === "no_available") {
          navigate(`/${Directory}/no_links_available`);
        } else if (peserta.data.status === "tes_psikologi") {
          if (peserta.data.hasil_mbti) {
            navigate(`/${Directory}/finish_submit_test/MBTI`);
          } else if (peserta.data.time !== 0) {
            const diff_min_peserta = peserta.data.diffmin;
            if (diff_min_peserta <= 0) {
              navigate(`/${Directory}/expired_link`);
            } else {
              setMinutes(diff_min_peserta);
            }
          } else {
            updateTime();
          }
        }
      } catch (error) {
        console.log(error);
        navigate(`/${Directory}/expired_link`);
      }
    };
    getPesertaById();
  }, [navigate, token]);

  useEffect(() => {
    const getAllSoalMbti1 = async () => {
      try {
        setIsLoading(true);
        let { data } = await axios.get(`${url}/getSoalMbti1`);
        setSoalMbti1(data);
      } catch (error) {
        console.log(error);
      }
    };
    const getAllSoalMbti2 = async () => {
      try {
        let { data } = await axios.get(`${url}/getSoalMbti2`);
        setSoalMbti2(data);
      } catch (error) {
        console.log(error);
      }
    };
    const getAllSoalMbti3 = async () => {
      try {
        let { data } = await axios.get(`${url}/getSoalMbti3`);
        setSoalMbti3(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getAllSoalMbti1();
    getAllSoalMbti2();
    getAllSoalMbti3();
  }, []);

  const handleJawabanChange = (idx, bagian, tipe, value) => {
    const updatedJawaban = {
      ...jawaban,
      [idx + bagian + "tipe" + tipe]: value,
    };
    setJawaban(updatedJawaban);
    localStorage.setItem(
      `jawabanMbtiSiswa${token}`,
      JSON.stringify(updatedJawaban)
    );

    const storedJawaban =
      JSON.parse(localStorage.getItem(`jawabanMbtiSiswa${token}`)) || {};

    const newJawaban = {
      ...storedJawaban,
      [idx + bagian + "tipe" + tipe]: value,
    };

    localStorage.setItem(
      `jawabanMbtiSiswa${token}`,
      JSON.stringify(newJawaban)
    );

    setValue(idx + bagian + "tipe" + tipe, value);
  };

  if (error) {
    return <Navigate to={`/${Directory}/expired_link`} />;
  } else {
    return (
      <div>
        <title>Ganesha Operation | Tes Mbti Siswa</title>
        <Navbar />
        {isLoading && (
          <div
            style={{
              height: "80vh",
              position: "relative",
              fontSize: "30px",
              fontWeight: "bolder",
            }}
          >
            <div className="text-center text-danger vertical-center">
              <div
                className="spinner-border"
                role="status"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Loading...</div>
            </div>
          </div>
        )}
        {!isLoading && (
          <>
            {" "}
            <div>{countDown}</div>
            <form onSubmit={handleSubmit(onSubmit)} className="container fs-4">
              <div className="text-center" style={{ marginTop: "100px" }}>
                <div>
                  <h1>Bagian I</h1>
                </div>
                <div>
                  <b>Petunjuk:</b> Pilih salah satu pernyataan dari tiap soal
                  yang lebih mengungkapkan bagaimana biasanya anda merasa atau
                  bertindak. Buatlah pilihan secara spontan!
                </div>
              </div>
              <div className="row" style={{ marginTop: "100px" }}>
                {soalMbti1.map((el, index) => {
                  return (
                    <div key={el._id} className="col-12 mt-3">
                      <div>
                        <b>
                          {index + 1}. {el.soal}
                        </b>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={index + "bagian1" + "tipe" + el.tipe}
                          id={index + "radio1"}
                          value={el.valueA}
                          {...register(index + "bagian1" + "tipe" + el.tipe, {
                            required: true,
                          })}
                          onChange={(e) =>
                            handleJawabanChange(
                              index,
                              "bagian1",
                              el.tipe,
                              e.target.value
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={index + "radio1"}
                        >
                          {el.jawabanA}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={index + "bagian1"}
                          id={index + "radio2"}
                          value={el.valueB}
                          {...register(index + "bagian1" + "tipe" + el.tipe, {
                            required: true,
                          })}
                          onChange={(e) =>
                            handleJawabanChange(
                              index,
                              "bagian1",
                              el.tipe,
                              e.target.value
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={index + "radio2"}
                        >
                          {el.JawabanB}
                        </label>
                      </div>
                      {errors[index + "bagian1" + "tipe" + el.tipe]?.type ===
                        "required" && (
                        <p
                          className="text-center"
                          role="alert"
                          style={{ color: "red" }}
                        >
                          Option tidak boleh kosong
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="text-center mt-5">
                <div>
                  <h1>Bagian II</h1>
                </div>
                <div>
                  <b>Petunjuk :</b> Pilih salah satu kata yang lebih
                  menggambarkan diri Anda.
                </div>
              </div>
              <div className="row mt-5">
                {soalMbti2.map((el, index) => {
                  return (
                    <div key={el._id} className="col-12 mt-3">
                      <div className="d-flex">
                        <div className="me-3">
                          <b>{index + 61}.</b>{" "}
                        </div>
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={index + "bagian2" + "tipe" + el.tipe}
                              id={index + "radio1bg2"}
                              value={el.valueA}
                              {...register(
                                index + "bagian2" + "tipe" + el.tipe,
                                {
                                  required: true,
                                }
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={index + "radio1bg2"}
                            >
                              {el.jawabanA}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={index + "bagian2"}
                              id={index + "radio2bg2"}
                              value={el.valueB}
                              {...register(
                                index + "bagian2" + "tipe" + el.tipe,
                                {
                                  required: true,
                                }
                              )}
                              onChange={(e) =>
                                handleJawabanChange(
                                  index,
                                  "bagian2",
                                  el.tipe,
                                  e.target.value
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={index + "radio2bg2"}
                            >
                              {el.JawabanB}
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors[index + "bagian2" + "tipe" + el.tipe]?.type ===
                        "required" && (
                        <p
                          className="text-center"
                          role="alert"
                          style={{ color: "red" }}
                        >
                          Option tidak boleh kosong
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="text-center mt-5">
                <div>
                  <h1>Bagian III</h1>
                  <div>
                    <b>Petunjuk :</b> Pilih salah satu pernyataan yang lebih
                    menggambarkan diri Anda.
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                {soalMbti3.map((el, index) => {
                  return (
                    <div key={el._id} className="col-12">
                      <div className="d-flex mt-3">
                        <div className="me-3">
                          <b>{index + 97}.</b>{" "}
                        </div>
                        <div className="row">
                          <div>
                            <div className="form-check col-lg-12">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={index + "bagian3" + "tipe" + el.tipe}
                                id={index + "radio1bg3"}
                                value={el.valueA}
                                {...register(
                                  index + "bagian3" + "tipe" + el.tipe,
                                  {
                                    required: true,
                                  }
                                )}
                                onChange={(e) =>
                                  handleJawabanChange(
                                    index,
                                    "bagian3",
                                    el.tipe,
                                    e.target.value
                                  )
                                }
                              />
                              <div>{el.jawabanA}</div>
                            </div>
                            <div>
                              <div className="form-check col-lg-12">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={index + "bagian3"}
                                  id={index + "radio2bg3"}
                                  value={el.valueB}
                                  {...register(
                                    index + "bagian3" + "tipe" + el.tipe,
                                    {
                                      required: true,
                                    }
                                  )}
                                  onChange={(e) =>
                                    handleJawabanChange(
                                      index,
                                      "bagian3",
                                      el.tipe,
                                      e.target.value
                                    )
                                  }
                                />
                                {/* <label
                                                            htmlFor={
                                                                index +
                                                                "radio2bg3"
                                                            }
                                                        >
                                                            <b>
                                                                {
                                                                    el.soalB
                                                                }
                                                            </b>
                                                        </label> */}
                                <div>{el.jawabanB}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {errors[index + "bagian3" + "tipe" + el.tipe]?.type ===
                        "required" && (
                        <p
                          className="text-center"
                          role="alert"
                          style={{ color: "red" }}
                        >
                          Option tidak boleh kosong
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="text-center" style={{ margin: "150px 0" }}>
                <button
                  disabled={handleLoadBut}
                  type="submit"
                  className="btn btn-danger w-50"
                >
                  {handleLoadBut ? "Mengirim Jawaban ..." : "Submit"}
                </button>
              </div>
            </form>{" "}
          </>
        )}
      </div>
    );
  }
};

export default TesMbti;

import {
  FETCH_ALL_SOAL_DISC,
  FETCH_ALL_SOAL_MBTI1,
  FETCH_ALL_SOAL_MBTI2,
  FETCH_ALL_SOAL_MBTI3,
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  GET_ALL_KARYAWAN,
  CHECK_NISN,
} from "../Constanst";
import axios from "axios";
import url from "../../baseUrl";

export const getAllSoalDisc = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${url}/getSoalDisc`);
    console.log(data, "ini soal disc redux");
    dispatch({
      type: FETCH_ALL_SOAL_DISC,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllSoalMbti1 = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${url}/getSoalMbti1`);
    console.log(data, "get Soal mbti 1");
    dispatch({
      type: FETCH_ALL_SOAL_MBTI1,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAllSoalMbti2 = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${url}/getSoalMbti2`);
    dispatch({
      type: FETCH_ALL_SOAL_MBTI2,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAllSoalMbti3 = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${url}/getSoalMbti3`);
    dispatch({
      type: FETCH_ALL_SOAL_MBTI3,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllKaryawan = (token) => async (dispatch) => {
  try {
    let { data } = await axios.get(`${url}/getAllKaryawan`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch({
      type: GET_ALL_KARYAWAN,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const AdminLogin = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_LOGIN });
  } catch (error) {
    console.log(error);
  }
};

export const AdminLogout = () => async (dispatch) => {
  try {
    localStorage.removeItem("LoginSdm");
    dispatch({ type: ADMIN_LOGOUT });
  } catch (error) {
    console.log(error, "ini error redux logout");
  }
};

export const CheckNisn =
  (nisn, kodeEvent, tanggal_lahir, setError, clearNisn) => async (dispatch) => {
    try {
      console.log(tanggal_lahir);
      if (clearNisn) {
        dispatch({ type: CHECK_NISN, payload: { isNisn: false } });
        return { payload: { isNisn: false } };
      }
      const { data } = await axios.get(
        `${url}/checknisn/${nisn}/${kodeEvent}/${tanggal_lahir}`
      );
      dispatch({ type: CHECK_NISN, payload: data });
      return { payload: data };
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
      return { payload: { error: true, message: error.response.data.message } };
    }
  };

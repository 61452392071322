import { CHECK_NISN } from "../../../Constanst";

const initialState = {
  isNisn: false,
  namaUser: "",
  tanggalTes: "",
  nisn: "",
  kodeEvent: "",
};

const checkNisn = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_NISN:
      return {
        ...state,
        isNisn: action.payload.isNisn === false ? false : true,
        namaUser: action.payload.nama,
        tanggalTes: action.payload.tanggal_tes,
        nisn: action.payload.nisn,
        kodeEvent: action.payload.kodeEvent,
      };

    default:
      return state;
  }
};

export default checkNisn;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Directory from "../../baseDirectory";
import baseUrl from "../../baseUrl";
import axios from "axios";
import CSVReader from "../../Components/paparse/importCSV";
import CSVDownloader from "../../Components/paparse/sampleCSV";
import NavbarAdmin from "../../Components/NavbarAdmin";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst";
import { useSelector, useDispatch } from "react-redux";

const AddPeserta = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const [dataCsv, setDataCsv] = useState([]);
  const [isLoadBut, setIsLoadBut] = useState(false);
  const tokenJwt = useSelector((state) => state.admTokenReducer.data.token);
  const expire = jwt_decode(tokenJwt).exp;

  const onSubmit = async () => {
    setIsLoadBut(true);
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(
      async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(`${baseUrl}/accessToken`);
          config.headers.Authorization = response.data.adm_access_token;
          dispatch({
            type: GET_TOKEN,
            payload: {
              token: response.data.adm_access_token,
            },
          });
        }
        return config;
      },
      (error) => {
        axiosJWT.get(`${baseUrl}/logout`).then(() => {});
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
        return Promise.reject(error);
      }
    );
    try {
      if (!dataCsv || dataCsv.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Masukan Data Csv!",
        });
      } else {
        await axiosJWT.post(
          `${baseUrl}/peserta/${eventId}`,
          {
            eventId,
            peserta: dataCsv,
          },
          {
            headers: {
              Authorization: tokenJwt,
            },
          }
        );
        setIsLoadBut(false);
        navigate(`/${Directory}/detail_event/${eventId}`);
      }
    } catch (error) {
      setIsLoadBut(false);
      console.log(error.response.data.msg);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${
          error.response.data.msg
            ? error.response.data.msg
            : "Something went wrong!"
        }`,
      });
    }
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await axios.get(`${baseUrl}/accessToken`);
        dispatch({
          type: GET_TOKEN,
          payload: {
            token: res.data.adm_access_token,
          },
        });
      } catch (error) {
        axios.get(`${baseUrl}/logout`).then(() => {});
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
      }
    };
    getToken();
  }, [dispatch]);

  return (
    <div>
      <NavbarAdmin />
      <div className="container d-flex justify-content-center min-vh-100 align-items-center">
        <div className="row g-3 col-md-6 card p-5 m-5">
          <div className="mb-2">
            <small>
              Download <CSVDownloader />
            </small>
            <br />
            <br />
            <label className="form-label">Upload Peserta</label>
            <br />
            <CSVReader setDataCsv={setDataCsv} />
            <div className="d-flex justify-content-center m-3">
              <button
                disabled={isLoadBut}
                className="btn btn-danger"
                onClick={() => {
                  onSubmit();
                }}
              >
                {isLoadBut ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPeserta;

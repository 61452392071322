export const FETCH_ALL_SOAL_DISC = "FETCH_ALL_SOAL_DISC";
export const FETCH_ALL_SOAL_MBTI1 = "FETCH_ALL_SOAL_MBTI1";
export const FETCH_ALL_SOAL_MBTI2 = "FETCH_ALL_SOAL_MBTI2";
export const FETCH_ALL_SOAL_MBTI3 = "FETCH_ALL_SOAL_MBTI3";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const GET_TOKEN = "GET_TOKEN";
export const GET_ALL_KARYAWAN = "GET_ALL_KARYAWAN";
export const RESET_STORE = "RESET_STORE";
export const CHECK_NISN = "CHECK_NISN";
export const RESET_NISN = "RESET_NISN";

import React, { useState } from "react";
import Select from "react-select";
import "./LevelJabatan.css";
import leveljabatan from "../Assets/leveljabatan.bmp";
import NavbarAdmin from "../Components/NavbarAdmin";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Directory from "../baseDirectory";

// import { colourOptions } from '../data';

// const options = [
//     { value: "blues", label: "Blues" },
//     { value: "rock", label: "Rock" },
//     { value: "jazz", label: "Jazz" },
//     { value: "orchestra", label: "Orchestra" },
// ];

const jabatan = [
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Bagian",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Cabang",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Pelayanan",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Rayon",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Sekretariat",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Seksi",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Unit",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Kepala Wilayah",
    },
    {
        Kewilayahan: "Pusat",
        Jabatan: "Koordinator Mata Pelajaran",
    },
    {
        Kewilayahan: "Non Pusat",
        Jabatan: "Staf",
    },
    {
        Kewilayahan: "Pusat",
        Jabatan: "Kepala Bagian",
    },
    {
        Kewilayahan: "Pusat",
        Jabatan: "Kepala Seksi",
    },
    {
        Kewilayahan: "Pusat",
        Jabatan: "Manajer/Koordinator Kepala Bagian",
    },
    {
        Kewilayahan: "Pusat",
        Jabatan: "Staf",
    },
];

const LevelJabatan = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [options, setOptions] = useState([]);
    const [disBut, setDisBut] = useState(true);
    const [disbutJabatan, setDisButJabatan] = useState(true);
    const [value, setValue] = useState("");
    const [penempatan, setPenempatan] = useState("");

    // const [isClearable, setIsClearable] = useState(true);
    // const [isSearchable, setIsSearchable] = useState(true);
    // const [isDisabled, setIsDisabled] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isRtl, setIsRtl] = useState(false);
    const handleChange = (e) => {
        setValue(e.value);
        setDisBut(false);
    };

    const handlePenempatan = (e) => {
        const filter = jabatan.filter((el) => {
            return el.Kewilayahan === e.value;
        });
        ConvertOption(filter);
        setDisButJabatan(false);
        setPenempatan(e.value);
    };

    const ConvertOption = (data) => {
        const result = [];
        data.forEach((element) => {
            result.push({
                value: element.Jabatan,
                label: element.Jabatan,
            });
        });
        setOptions(result);
    };

    return (
        <>
            <NavbarAdmin />
            <div className="containerlevel">
                <div className="row">
                    <div className="col-sm-6">
                        {" "}
                        <img
                            className="bg selectlevelhpscreen "
                            src={leveljabatan}
                            alt="level jabatan"
                        />
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="selectlevel">
                            <p className="mb-2">
                                Proyeksi Penempatan/Level
                            </p>
                            <Select
                                className="basic-single mt-3 mb-3"
                                classNamePrefix="select"
                                // defaultValue={colourOptions[0]}
                                // isDisabled={isDisabled}
                                // isLoading={isLoading}
                                // isClearable={true}
                                // isRtl={true}
                                // isSearchable={true}
                                options={[
                                    {
                                        value: "Pusat",
                                        label: "Pusat",
                                    },
                                    {
                                        value: "Non Pusat",
                                        label: "Non Pusat",
                                    },
                                ]}
                                placeholder="Pilih Penempatan"
                                onChange={handlePenempatan}
                            />
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                // defaultValue={colourOptions[0]}
                                // isDisabled={isDisabled}
                                // isLoading={isLoading}
                                // isClearable={true}
                                // isRtl={true}
                                isSearchable={true}
                                options={options}
                                placeholder="Pilih Jabatan"
                                onChange={handleChange}
                                isDisabled={disbutJabatan}
                            />
                        </div>
                        <div className="mt-3 text-center d-grid">
                            <button
                                className="btn btn-danger fw-bold"
                                type="button"
                                disabled={disBut}
                                onClick={() => {
                                    if (penempatan === "Pusat") {
                                        if (location.state) {
                                            navigate(
                                                `/${Directory}/form`,
                                                {
                                                    state: {
                                                        jabatan:
                                                            value,
                                                        penempatan:
                                                            penempatan,
                                                        karyawanId:
                                                            location
                                                                .state
                                                                .karyawanId,
                                                    },
                                                }
                                            );
                                        } else {
                                            navigate(
                                                `/${Directory}/form`,
                                                {
                                                    state: {
                                                        jabatan:
                                                            value,
                                                        penempatan:
                                                            penempatan,
                                                    },
                                                }
                                            );
                                        }
                                    } else if (
                                        penempatan === "Non Pusat"
                                    ) {
                                        if (location.state) {
                                            navigate(
                                                `/${Directory}/form_unit`,
                                                {
                                                    state: {
                                                        jabatan:
                                                            value,
                                                        penempatan:
                                                            penempatan,
                                                        karyawanId:
                                                            location
                                                                .state
                                                                .karyawanId,
                                                    },
                                                }
                                            );
                                        } else {
                                            navigate(
                                                `/${Directory}/form_unit`,
                                                {
                                                    state: {
                                                        jabatan:
                                                            value,
                                                        penempatan:
                                                            penempatan,
                                                    },
                                                }
                                            );
                                        }
                                    }
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="selectlevelhpscreen">
                <h1 className="mb-3">
                    Proyeksi Penempatan/Level Jabatan
                </h1>
                <Select
                    className="basic-single"
                    classNamePrefix=""
                    // defaultValue={colourOptions[0]}
                    // isDisabled={isDisabled}
                    // isLoading={isLoading}
                    // isClearable={true}
                    isSearchable={true}
                    name="color"
                    // value={value}
                    options={options}
                    placeholder="Pilih Jabatan"
                    onChange={handleChange}
                />
                <div className="d-grid gap-2">
                    <button
                        className="btn btn-danger fw-bold"
                        type="button"
                        disabled={disBut}
                    >
                        Submit
                    </button>
                </div>
            </div> */}
        </>
    );
};

export default LevelJabatan;

import React, { useState, useEffect, useRef } from "react";
import NavbarAdmin from "../../Components/NavbarAdmin";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../baseUrl";
import jwt_decode from "jwt-decode";
import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst";
import { useSelector, useDispatch } from "react-redux";
import Directory from "../../baseDirectory";
import Select from "react-select";
axios.defaults.withCredentials = true;

const FormEvent = () => {
  const kotaReff = useRef(null);
  const jenjangReff = useRef(null);
  const sekolahReff = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorBackend, setErrorBackend] = useState("");
  const [provData, setProvData] = useState([]);
  const [kotaData, setKotaData] = useState([]);
  let [idKota, setIdKota] = useState("");
  const [listSekolah, setListSekolah] = useState([]);

  // dis value
  const [disSelectKota, setDisselectKota] = useState(true);
  const [disSelectJenjang, setDisselectJenjanag] = useState(true);
  const [disSelectSekolah, setDisselectSekolah] = useState(true);
  // const [selectedKota, setSelectedKota] = useState();
  const tokenJwt = useSelector((state) => state.admTokenReducer.data.token);
  const expire = jwt_decode(tokenJwt).exp;

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${url}/accessToken`);
        config.headers.Authorization = response.data.adm_access_token;
        dispatch({
          type: GET_TOKEN,
          payload: {
            token: response.data.adm_access_token,
          },
        });
      }
      return config;
    },
    (error) => {
      axios.get(`${url}/logout`).then(() => {});
      localStorage.removeItem("LoginSdm");
      dispatch({ type: ADMIN_LOGOUT });
      return Promise.reject(error);
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // console.log(sekolahReff.current.getValue());
      const nama_sekolah = sekolahReff.current.getValue();

      const newData = { ...data, nama_sekolah: nama_sekolah[0].value };
      console.log(newData);
      await axiosJWT.post(`${url}/event`, newData, {
        headers: {
          Authorization: tokenJwt,
        },
      });
      navigate(`/${Directory}/list_event`);
    } catch (error) {
      if (error.response.data.message) {
        setErrorBackend(error.response.data.message);
      } else {
        setErrorBackend(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await axios.get(`${url}/accessToken`);
        dispatch({
          type: GET_TOKEN,
          payload: {
            token: res.data.adm_access_token,
          },
        });
      } catch (error) {
        axios.get(`${url}/logout`).then(() => {});
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
      }
    };
    getToken();
  }, [dispatch]);

  useEffect(() => {
    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(
      async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(`${url}/accessToken`);
          config.headers.Authorization = response.data.adm_access_token;
          dispatch({
            type: GET_TOKEN,
            payload: {
              token: response.data.adm_access_token,
            },
          });
        }
        return config;
      },
      (error) => {
        axios.get(`${url}/logout`).then(() => {});
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
        return Promise.reject(error);
      }
    );
  }, [expire, tokenJwt, dispatch]);

  useEffect(() => {
    const getProvData = async () => {
      try {
        const { data } = await axios.get(
          "https://support-api.ganeshaoperation.com/api/sekolah/province"
        );

        const newProvData = data?.data.map((prov, i) => ({
          value: prov.id,
          label: prov.nama_provinsi,
        }));

        setProvData(newProvData);
      } catch (error) {
        console.log(error);
      }
    };
    getProvData();
  }, []);

  const handleChangeProv = async (id_prov) => {
    try {
      const { data } = await axios.get(
        `https://support-api.ganeshaoperation.com/api/sekolah/cities?province_id=${id_prov}`
      );

      const newData = data?.data.map((kota, i) => ({
        value: kota.id,
        label: kota.nama_kota,
      }));
      setKotaData(newData);
      // kotaReff.current.clearValue();
      kotaReff.current.setValue("");
      jenjangReff.current.setValue("");
      sekolahReff.current.setValue("");
      // console.log(kotaReff.current.commonProps.getValue(), "ini get value");
      setDisselectKota(false);
    } catch (error) {
      setDisselectKota(true);
      console.log(error);
    }
  };

  const handleJenjangChange = async (tingkat) => {
    console.log(idKota);
    try {
      const { data } = await axios.get(
        `https://support-api.ganeshaoperation.com/api/sekolah/list?cities=${idKota}&tingkat=${tingkat}`
      );

      const newData = data.data.map((school) => ({
        value: school.nama_sekolah,
        label: school.nama_sekolah,
      }));
      setListSekolah(newData);
      setDisselectSekolah(false);
      sekolahReff.current.setValue("");
    } catch (error) {
      setDisselectSekolah(true);
      console.log(error);
    }
  };
  const handleKotaChange = (idKota) => {
    setIdKota(idKota);
    jenjangReff.current.setValue("");
    sekolahReff.current.setValue("");
    if (!idKota) {
      setDisselectJenjanag(true);
    } else setDisselectJenjanag(false);
  };

  return (
    <>
      <NavbarAdmin />
      <div className="container d-flex justify-content-center min-vh-100 align-items-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row g-3 col-md-6 card p-5 m-5"
        >
          <div className="mb-2">
            <label htmlFor="formGroupExampleInput" className="form-label">
              Nama Event
            </label>
            <input
              type="text"
              className="form-control"
              id="formGroupExampleInput"
              placeholder="Masukan event"
              {...register("nama_event", {
                required: true,
              })}
            />
          </div>
          <div className="row mt-2">
            <b>Data Sekolah</b>
            <div className="mb-2">
              <label htmlFor="formGroupExampleInput2" className="form-label">
                Provinsi Sekolah
              </label>
              <Select
                onChange={(e) => handleChangeProv(e.value)}
                options={provData}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="formGroupExampleInput2" className="form-label">
                Kota/Kab. Sekolah
              </label>
              <Select
                isDisabled={disSelectKota}
                isClearable={true}
                ref={kotaReff}
                onChange={(e) => handleKotaChange(e.value)}
                options={kotaData}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="formGroupExampleInput2" className="form-label">
                Jenjang Sekolah
              </label>
              <Select
                isDisabled={disSelectJenjang}
                ref={jenjangReff}
                onChange={(e) => handleJenjangChange(e.value)}
                options={[
                  {
                    value: "2",
                    label: "SMP",
                  },
                  {
                    value: "3",
                    label: "SMA",
                  },
                ]}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="formGroupExampleInput2" className="form-label">
                Nama Sekolah
              </label>
              <Select
                isDisabled={disSelectSekolah}
                ref={sekolahReff}
                options={listSekolah}
              />
            </div>
          </div>

          <div className="mb-2">
            <label htmlFor="formGroupExampleInput2" className="form-label">
              Tanggal Pelaksanaan
            </label>
            <input
              type="date"
              className="form-control"
              id="formGroupExampleInput2"
              {...register("tanggal_pelaksanaan", {
                required: true,
              })}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="formGroupExampleInput2" className="form-label">
              Publish Hasil
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register("publish_hasil", {
                required: true,
              })}
            >
              <option value={false}>Tidak</option>
              <option value={true}>Ya</option>
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="formGroupExampleInput2" className="form-label">
              Email Koordinator
            </label>
            <input
              type="email"
              className="form-control"
              id="formGroupExampleInput2"
              placeholder="Masukan email koordinator"
              {...register("email", {
                required: true,
              })}
            />
          </div>
          {/* <div className="mb-2">
            <small>Download <CSVDownloader /></small>
            <br />
            <br />
            <label className="form-label">
              Upload Peserta
            </label>
            <br />
            <CSVReader setDataCsv={setDataCsv} />
          </div> */}
          {errors.nama_event?.type === "required" && (
            <small role="alert" style={{ color: "red" }}>
              Nama event tidak boleh kosong
            </small>
          )}
          {errors.tanggal_pelaksanaan?.type === "required" && (
            <small role="alert" style={{ color: "red" }}>
              Tanggal tes tidak boleh kosong
            </small>
          )}
          {/* {errors.nama_sekolah?.type === "required" && (
            <small role="alert" style={{ color: "red" }}>
              Nama Sekolah tidak boleh kosong
            </small>
          )} */}
          {errors.email?.type === "required" && (
            <small role="alert" style={{ color: "red" }}>
              Email koordinator tidak boleh kosong
            </small>
          )}
          {errorBackend && (
            <p role="alert" style={{ color: "red" }}>
              {errorBackend}
            </p>
          )}
          <button className="btn btn-danger">Tambah Event</button>
        </form>
      </div>
    </>
  );
};

export default FormEvent;

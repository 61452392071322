import React, { useState, useEffect } from "react";
import NavbarAdmin from "../../Components/NavbarAdmin";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useNavigate } from "react-router-dom";
import { MdSend } from "react-icons/md";
import Directory from "../../baseDirectory";
import axios from "axios"
import baseUrl from "../../baseUrl"
import Swal from 'sweetalert2'
import jwt_decode from "jwt-decode";
import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst";
import { useSelector, useDispatch } from "react-redux";

const ListEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [event, setEvent] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [statusEmail, setStatusEmail] = useState("")
  const [disableSend, setDisableSend] = useState(false)

  const tokenJwt = useSelector(
    (state) => state.admTokenReducer.data.token
  );
  const expire = jwt_decode(tokenJwt).exp;

  const columns = [
    "No",
    "Tgl. Pelaksanaan",
    "Sekolah",
    "Nama Event",
    "Pub. Hasil",
    "Email",
    "Kode Event",
    {
      name: "Detail",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <button className="btn btn-danger" onClick={() => {
                navigate(`/${Directory}/detail_event/${value}`)
              }} >Lihat Detail</button>
            </div>
          )
        }
      }
    },
    {
      name: "Kirim Email",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const date = new Date(value.tanggal_pelaksanaan)
          date.setDate(date.getDate() + 1)
          return (
            <div>
              {
                value.statusEmail === 0 ? <button className="btn btn-danger"
                  disabled={date.getTime() > new Date().getTime() ? true : false || disableSend}
                  onClick={() => {
                    setDisableSend(true)
                    sendEmail(value.eventId)
                  }}
                ><MdSend /></button> : <button className="btn btn-danger" >Terkirim</button>
              }

            </div>
          )
        }
      }
    }
  ];

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "40%",
    pagination: true,
    rowHover: true,
    tableBodyMaxHeight: "500px",
    selectableRows: "none",
  };
  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await axios.get(`${baseUrl}/accessToken`);
        dispatch({
          type: GET_TOKEN,
          payload: {
            token: res.data.adm_access_token,
          },
        });
      } catch (error) {
        axios.get(`${baseUrl}/logout`).then(() => { });
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
      }
    };
    getToken();
  }, [dispatch]);

  useEffect(() => {
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(
      async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(
            `${baseUrl}/accessToken`
          );
          config.headers.Authorization =
            response.data.adm_access_token;
          dispatch({
            type: GET_TOKEN,
            payload: {
              token: response.data.adm_access_token,
            },
          });
        }
        return config;
      },
      (error) => {
        axiosJWT.get(`${baseUrl}/logout`).then(() => { });
        localStorage.removeItem("LoginSdm");
        dispatch({ type: ADMIN_LOGOUT });
        return Promise.reject(error);
      }
    );
    const getEvent = async () => {
      try {
        const { data } = await axiosJWT.get(`${baseUrl}/event`, {
          headers: {
            Authorization: tokenJwt
          }
        })
        ConvertDataEvent(data)
      } catch (error) {
        console.log(error)
      }
    }
    getEvent()
  }, [statusEmail, dispatch, expire])

  const sendEmail = async (eventId) => {
    try {
      const axiosJWT = axios.create();
      axiosJWT.interceptors.request.use(
        async (config) => {
          const currentDate = new Date();
          if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(
              `${baseUrl}/accessToken`
            );
            config.headers.Authorization =
              response.data.adm_access_token;
            dispatch({
              type: GET_TOKEN,
              payload: {
                token: response.data.adm_access_token,
              },
            });
          }
          return config;
        },
        (error) => {
          axiosJWT.get(`${baseUrl}/logout`).then(() => { });
          localStorage.removeItem("LoginSdm");
          dispatch({ type: ADMIN_LOGOUT });
          return Promise.reject(error);
        }
      );
      const { data } = await axiosJWT.get(`${baseUrl}/pesertasend/${eventId}`, {
        headers: {
          Authorization: tokenJwt
        }
      })
      setStatusEmail(data.msg)
      setDisableSend(false)
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Email sudah terkirim",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  }


  const ConvertDataEvent = (data) => {
    const result = []
    let obj = {}
    data.forEach((element, index) => {
      obj.No = index + 1
      obj["Tgl. Pelaksanaan"] = new Date(element.tanggal_pelaksanaan).toLocaleDateString('en-GB')
      obj.Sekolah = element.nama_sekolah
      obj["Nama Event"] = element.nama_event
      obj["Pub. Hasil"] = element.publish_hasil ? "iya" : "tidak"
      obj.Email = element.email
      obj.Detail = element._id
      obj["Kirim Email"] = {
        statusEmail: element.status_email,
        eventId: element._id,
        tanggal_pelaksanaan: element.tanggal_pelaksanaan
      }
      obj["Kode Event"] = element.kodeEvent
      result.push(obj)
      obj = {}
    });
    setEvent(result)
    setIsLoading(false)
  }

  return (
    <div>
      <NavbarAdmin />;
      {
        isLoading && (
          (
            <div
              style={{
                height: "80vh",
                position: "relative",
                fontSize: "30px",
                fontWeight: "bolder",
              }}
            >
              <div className="text-center text-danger vertical-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <span className="visually-hidden">
                    Loading...
                  </span>
                </div>
                <div>Loading...</div>
              </div>
            </div>
          )
        )
      }
      {!isLoading && (
        <div className="container mt-5">
          <div className="text-end mt-3 mb-3">
            <button
              onClick={() => {
                navigate(`/${Directory}/form_event`);
              }}
              type="button"
              className="btn btn-danger"
            >
              Tambah Event
            </button>
          </div>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                title={"Event List"}
                data={event}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>
        </div>
      )}
    </div>
  );
};

export default ListEvent;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CheckNisn } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import Directory from "../../baseDirectory";
import { useNavigate } from "react-router-dom";
import Logogo2 from "../../Assets/logogo2.png";
const MasukTes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loadingBut, setLoadingBut] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    setLoadingBut(true);
    dispatch(CheckNisn(data.nisn, data.kodeEvent, data.tanggal_lahir, setError))
      .then((result) => {
        console.log(result);
        // Cek apakah permintaan berhasil berdasarkan payload
        if (result && result.payload && !result.payload.error) {
          navigate(`/${Directory}/start`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingBut(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container d-flex justify-content-center min-vh-100 align-items-center">
          <div className="row g-3 col-md-6 card p-5">
            <div className="d-flex justify-content-center">
              <img src={Logogo2} height={"130px"} alt="Ganesha Operation" />
            </div>
            <div className="d-flex justify-content-center">
              <h3>Tes Mbti Ganesha Operation</h3>
            </div>
            <label className="fw-bold">Masukan NISN</label>
            <input
              name="NISN"
              type="number"
              className="form-control"
              id="inputNISN"
              placeholder="Masukan NISN Peserta"
              style={{ marginBottom: 10 }}
              {...register("nisn", {
                required: true,
                // minLength: 16,
              })}
            />
            {errors.nisn?.type === "required" && (
              <span role="alert" style={{ color: "red" }}>
                Masukan nisn
              </span>
            )}
            <label className="fw-bold">Masukan Kode Event</label>
            <input
              name="Kode Event"
              type="text"
              className="form-control"
              id="inputKode"
              placeholder="Masukan kode event"
              {...register("kodeEvent", {
                required: true,
              })}
            />
            {errors.kodeEvent?.type === "required" && (
              <span role="alert" style={{ color: "red" }}>
                Masukan kode event
              </span>
            )}
            <label className="fw-bold">Masukan Tanggal Lahir Anda</label>
            <input
              name="Tanggal Lahir"
              type="date"
              className="form-control"
              id="inputDate"
              placeholder="Masukan Tanggal Lahir"
              {...register("tanggal_lahir", {
                required: true,
              })}
            />

            {errors.tanggal_lahir?.type === "required" && (
              <span role="alert" style={{ color: "red" }}>
                Masukan Tanggal Lahir
              </span>
            )}
            {error && (
              <p role="alert" style={{ color: "red" }}>
                {error}
              </p>
            )}

            <button
              disabled={loadingBut}
              className="btn btn-danger"
              type="submit"
            >
              {loadingBut ? "Loading..." : "Masuk Tes"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MasukTes;

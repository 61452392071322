import React, { useState } from "react";
import url from "../baseUrl";
import { useDispatch } from "react-redux";
import Gowhite from "../Assets/logoGo.png";

import { useForm } from "react-hook-form";
import { AdminLogin } from "../Redux/Actions";
import { GET_TOKEN } from "../Redux/Constanst";
import axios from "axios";
import "./LoginAdmin.css";
axios.defaults.withCredentials = true;

const Loginadmin = () => {
    const [errorBackend, setErrorBackend] = useState();

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            localStorage.setItem("LoginSdm", true);
            const response = await axios.post(`${url}/login`, data, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });
            dispatch({
                type: GET_TOKEN,
                payload: {
                    token: response.data.accessToken,
                },
            });
            dispatch(AdminLogin());
        } catch (error) {
            setErrorBackend(error.response.data.msg);
        }
    };

    return (
        <div className="containerloginadmin">
            <div className="d-flex position-absolute top-50 start-50 translate-middle">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formlogin"
                >
                    <div className="mb-3">
                        <div className="logologin">
                            <img
                                src={Gowhite}
                                alt="Ganesha Operation"
                            />
                        </div>
                        <div className="loginadmin">
                            <h3>Masuk Admin</h3>
                        </div>
                        <div className="inputlogin">
                            <input
                                type="txt"
                                className="form-control form-control-lg"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="username"
                                {...register("username", {
                                    required: true,
                                    onChange: (e) => {
                                        setErrorBackend("");
                                    },
                                })}
                            />

                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control form-control-lg"
                                    id="exampleInputPassword1"
                                    placeholder="password"
                                    {...register("password", {
                                        required: true,
                                        minLength: 8,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-center m-3">
                        <div style={{ color: "yellow" }}>
                            <small>
                                {errors.username?.type ===
                                    "required" &&
                                    "Username tidak boleh kosong!"}
                            </small>
                        </div>
                        <div style={{ color: "yellow" }}>
                            <small>
                                {errors.password?.type ===
                                    "required" &&
                                    "Password tidak boleh kosong!"}
                            </small>
                        </div>
                        <div style={{ color: "yellow" }}>
                            <small>
                                {errors.password?.type ===
                                    "minLength" &&
                                    "Panjang minimal password 6!"}
                            </small>
                        </div>
                        <div style={{ color: "yellow" }}>
                            <small>{errorBackend}</small>
                        </div>
                    </div>
                    <div className="btnlogin">
                        <button
                            type="submit"
                            className="btn btn-primary fw-bolder"
                        >
                            Masuk
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Loginadmin;

import React, { useState } from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import Swal from "sweetalert2";
const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

function csvToJson(data) {
  const keys = data[0];
  const arrayOfObjects = [];

  for (let i = 1; i < data.length; i++) {
    const currentArray = data[i];
    const currentObject = {};
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      const value = currentArray[j];
      if (value) {
        currentObject[key] = value;
      }
    }
    if (Object.keys(currentObject).length !== 0) {
      arrayOfObjects.push(currentObject);
    }
  }

  return arrayOfObjects;
}

function CSVReader({ setDataCsv }) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const [acceptedCsv, setAcceptedFile] = useState(null);

  const checkCSVFormat = (data) => {
    // Define the expected fields
    console.log(data);
    const expectedFields = [
      "nisn",
      "nama",
      "tanggal_lahir",
      "no_hp",
      "email",
      "kelas",
      "jenjang_pendidikan",
    ];

    // Check if all expected fields are present in the first row (header) of the CSV
    const headerFields = data[0];
    for (let field of expectedFields) {
      if (!headerFields.includes(field)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "FORMAT CSV TIDAK SESUAI",
        });
        return false;
      }
    }
    const nisnIndex = headerFields.indexOf("nisn");
    for (let i = 1; i < data.length - 1; i++) {
      const nisn = String(data[i][nisnIndex]).trim();
      console.log(nisn, "ini nisn");
      if (nisn.length !== 10 || !/^\d+$/.test(nisn)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "NISN HARUS 10 DIGIT !",
        });
        return false;
      }
    }

    return true;
  };

  return (
    <React.Fragment>
      <CSVReader
        config={{
          error: (err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.msg,
            });
          },
        }}
        onUploadRejected={() =>
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Format File Harus CSV!",
          })
        }
        onUploadAccepted={(results) => {
          if (!checkCSVFormat(results.data)) {
            setAcceptedFile(null);
            return;
          }
          setAcceptedFile(results);

          setDataCsv(csvToJson(results.data));
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();

          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          ProgressBar,
          getRemoveFileProps,
          Remove,
          acceptedFile,
        }) => {
          return (
            <>
              <div
                {...getRootProps()}
                style={{ ...styles.zone, ...(zoneHover && styles.zoneHover) }}
              >
                {acceptedCsv && acceptedFile ? (
                  <>
                    <div style={styles.file}>
                      <div style={styles.info}>
                        <span style={styles.size}>
                          {formatFileSize(acceptedFile.size)}
                        </span>
                        <span style={styles.name}>{acceptedFile.name}</span>
                      </div>
                      <div style={styles.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        {...getRemoveFileProps()}
                        style={styles.remove}
                        onMouseOver={(event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                        onMouseOut={(event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  </>
                ) : (
                  "Letakkan file CSV di sini atau klik untuk mengunggah"
                )}
              </div>
            </>
          );
        }}
      </CSVReader>
    </React.Fragment>
  );
}

export default CSVReader;

import React, { useState, useEffect } from "react";
import NavbarAdmin from "../../Components/NavbarAdmin";
import "./ListKaryawan.css";

import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst";
import { useSelector, useDispatch } from "react-redux";

import url from "../../baseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Directory from "../../baseDirectory";
axios.defaults.withCredentials = true;

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
});

const ListKaryawan = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [karyawan, setKaryawan] = useState([]);
    const dataKaryawan = useSelector((state) => state.data.karyawan);

    useEffect(() => {
        const getToken = async () => {
            try {
                const res = await axios.get(`${url}/accessToken`);
                dispatch({
                    type: GET_TOKEN,
                    payload: {
                        token: res.data.adm_access_token,
                    },
                });
            } catch (error) {
                axios.get(`${url}/logout`).then(() => {});
                localStorage.removeItem("LoginSdm");
                dispatch({ type: ADMIN_LOGOUT });
            }
        };
        getToken();
    }, [dispatch]);

    const columns = [
        "No",
        "Nik",
        "Nama",
        {
            name: "TempatLahir",
            label: "Tempat Lahir",
        },
        {
            name: "TanggalLahir",
            label: "Tanggal Lahir",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <div>
                                {new Date(value).toLocaleDateString(
                                    "en-GB"
                                )}
                            </div>
                        </div>
                    );
                },
            },
        },
        "Email",
        {
            name: "PhoneNumber",
            label: "No Handphone",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <div>0{value}</div>
                        </div>
                    );
                },
            },
        },
        {
            name: "Aksi",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <span
                                className="badge bg-danger m-4"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "15px",
                                }}
                                onClick={() => {
                                    navigate(
                                        `/${Directory}/level_jabatan`,
                                        {
                                            state: {
                                                karyawanId: value,
                                            },
                                        }
                                    );
                                }}
                            >
                                Buat Peserta
                            </span>
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        tableBodyHeight: "40%",
        pagination: true,
        rowHover: true,
        tableBodyMaxHeight: "500px",
        // onTableChange: (action, state) => {
        //   console.log(action);
        //   console.dir(state);
        // },
        selectableRows: "none",
    };

    useEffect(() => {
        setKaryawan(dataKaryawan);
    }, [dataKaryawan]);

    return (
        <div>
            <NavbarAdmin />
            {karyawan.length === 0 && (
                <div
                    style={{
                        height: "80vh",
                        position: "relative",
                        fontSize: "30px",
                        fontWeight: "bolder",
                    }}
                >
                    <div className="text-center text-danger vertical-center">
                        <div
                            className="spinner-border"
                            role="status"
                            style={{
                                width: "50px",
                                height: "50px",
                            }}
                        >
                            <span className="visually-hidden">
                                Loading...
                            </span>
                        </div>
                        <div>Loading...</div>
                    </div>
                </div>
            )}
            {karyawan.length !== 0 && (
                <CacheProvider value={muiCache}>
                    <ThemeProvider theme={createTheme()}>
                        <MUIDataTable
                            data={karyawan}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </CacheProvider>
            )}
        </div>
    );
};

export default ListKaryawan;

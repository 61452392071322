import React, { useState, useEffect } from "react";
import People from "../../Assets/Peoples.png";
import "./Start.css";
import { Navbar } from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";
// import decode from "jwt-decode";
import axios from "axios";
import url from "../../baseUrl";
import Directory from "../../baseDirectory";
import { useSelector } from "react-redux";

const Start = () => {
  const navigate = useNavigate();
  // const { token } = useParams();
  const { namaUser, nisn, kodeEvent } = useSelector((state) => state.checkNisn);

  const [jenisTes, setJenisTes] = useState("");
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPesertaById = async () => {
      try {
        await axios.get(`${url}/getPesertaByIdUser/${nisn}/${kodeEvent}`);
        setJenisTes("MBTI");
      } catch (error) {
        console.log(error);
        setExpired(true);
      }
    };
    getPesertaById();
  }, []);

  const handleClick = () => {
    setLoading(true);
    if (jenisTes === "MBTI") {
      navigate(`/${Directory}/tes_mbti`);
      setLoading(false);
    } else if (jenisTes === "DISC") {
      navigate(`/${Directory}/tes_disc`);
      setLoading(false);
    }
  };

  if (expired) {
  } else {
    return (
      <div>
        <Navbar />

        <div className="container">
          <div className="text-center my-5 pt-3">
            <h3>
              <span className="fw-bold">HI {namaUser}</span> Selamat Datang!
            </h3>
          </div>
          <div className="d-flex justify-content-center align-content-between pt-2 gap-5">
            <div className="col-12 col-lg-6">
              <div class="card-m-test">
                <h2 className="text-danger fw-bold">Instruksi Tes !!!</h2>
                <ol>
                  <li>
                    Tes dilaksanakan selama 1 hari, dimulai dari pukul 00.00.00
                    sampai dengan pukul 23.59.
                  </li>
                  <li>
                    Waktu tes berlangsung selama 30 menit, dimulai dari peserta
                    menekan tombol "Mulai Tes".
                  </li>
                  <li>Peserta harus menjawab semua pertanyaan.</li>
                  <li>
                    Peserta yang menyelesaikan tes kurang dari 30 menit akan
                    dianggap sudah melaksanakan tes dan akan mendapatkan hasil
                    tes.
                  </li>
                  <li>
                    Peserta yang melewati batas waktu akan dianggap sudah
                    menyelesaikan tes, namun tidak akan mendapatkan hasil.
                  </li>
                  <li>
                    Apabila browser pada perangkat secara tidak sengaja
                    tertutup, peserta diharuskan untuk membuka kembali di
                    browser yang sama.
                  </li>
                  <li>
                    Membuka kembali dengan menggunakan browser yang berbeda akan
                    menyebabkan jawaban sebelumnya hilang.
                  </li>
                </ol>
              </div>
              <div className="d-flex justify-content-between mx-3">
                <p>
                  <b></b>
                </p>
                <button
                  onClick={handleClick}
                  disabled={loading}
                  class="uiverse btn-danger"
                >
                  <span className="fw-bold text-white">
                    {loading ? "Loading..." : "Mulai Tes"}
                  </span>
                </button>
              </div>
            </div>
            <div className="col-6 d-none d-lg-block">
              <img src={People} className="gambarstartpage" alt="people" />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Start;

import React from "react";
import Directory from "../baseDirectory";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ExpiredLink from "../Pages/ExpiredLink";
import FinishSubmitTest from "../Pages/FinishSubmitTest";
import Start from "../Pages/user/Start";
import LevelJabatan from "../Pages/LevelJabatan";
import TesMbti from "../Pages/user/TesMbti";
import AdminRoute from "./adminRoute";
import LinkBelumAda from "../Pages/LinkBelumAda";
import Blank from "../Pages/Blank";
import MasukTes from "../Pages/user/MasukTes";
import { useNavigate } from "react-router-dom";
import CheckResult from "../Pages/user/Check";
import HasilMbti_Public from "../Pages/user/HasilMbti_Public";

const Router = () => {
  const auth = useSelector((state) => state.checkNisn);

  const { isNisn } = auth;

  return (
    <Routes>
      <Route path={`/${Directory}/expired_link`} element={<ExpiredLink />} />
      <Route
        path={`/${Directory}/no_links_available`}
        element={<LinkBelumAda />}
      />
      <Route
        path={`/${Directory}/finish_submit_test/:JenisTes`}
        element={<FinishSubmitTest />}
      />
      <Route path={`/${Directory}/masuk`} element={<MasukTes />} />
      <Route path={`/${Directory}/check`} element={<CheckResult />} />
      <Route path={`/${Directory}/result`} element={<HasilMbti_Public />} />
      <Route
        path={`/${Directory}/start`}
        element={isNisn ? <Start /> : <Navigate to={`/${Directory}/masuk`} />}
      />
      <Route path={`/${Directory}/level_jabatan`} element={<LevelJabatan />} />
      <Route
        path={`/${Directory}/tes_mbti`}
        element={isNisn ? <TesMbti /> : <Navigate to={`/${Directory}/masuk`} />}
      />
      <Route path={`/${Directory}/*`} element={<AdminRoute />} />
      <Route path="/not_found" element={<Blank />} />
      <Route path={`/${Directory}`} element={<Blank />} />
    </Routes>
  );
};

export default Router;

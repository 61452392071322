import React, { useEffect ,useState} from "react";
import "./FinishSubmitTest.css";
import Peoplestar from "../Assets/Peoples.png";
import { Navbar } from "../Components/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CheckNisn } from "../Redux/Actions";
const FinishSubmitTest = () => {
  const dispatch = useDispatch()
  const { JenisTes } = useParams();
  const [error,setError]= useState(false)
  //   const navigate = useNavigate();

  // useEffect(() => {
  //     if (JenisTes === "MBTI" || JenisTes === "DISC") {
  //     } else {
  //         navigate("/not_found");
  //     }
  // }, [JenisTes, navigate]);

  useEffect(() => {
    try {
      
      dispatch(CheckNisn(undefined, undefined, setError, true));
    } catch (error) {
      console.log(error);
      setError(true)
    }
  }, [])
  

  return (
    <>
      <Navbar />
      <div className="finishsubmittest">
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="bgg">
            <img className="peoplesubmit" src={Peoplestar} alt="people" />
          </div>
        </div>

        <div className="Jawabantersimpan">
          <h2>Jawaban Tersimpan</h2>

          <i className="bi bi-check-circle-fill" style={{ fontSize: "450%" }} />
          <div className="thanks-finishsubmit">
            <h2>Terima kasih</h2>
            <h6>Anda telah menyelesaikan tes {JenisTes}</h6>
            <h6>Ganesha Operation</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinishSubmitTest;

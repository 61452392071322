import React, { useState, useEffect } from 'react'
import NavbarAdmin from "../../Components/NavbarAdmin"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import url from "../../baseUrl"
import jwt_decode from "jwt-decode"
import { ADMIN_LOGOUT, GET_TOKEN } from "../../Redux/Constanst"
import { useSelector, useDispatch } from "react-redux"
import Directory from "../../baseDirectory"
axios.defaults.withCredentials = true;

const EditPeserta = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenJwt = useSelector(
        (state) => state.admTokenReducer.data.token
    );
    const [isLoading, setIsLoading] = useState(true)
    const [dataPeserta, setDataPeserta] = useState({})
    const { pesertaId, eventId } = useParams()
    const expire = jwt_decode(tokenJwt).exp;
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expire * 1000 < currentDate.getTime()) {
                const response = await axios.get(
                    `${url}/accessToken`
                );
                config.headers.Authorization =
                    response.data.adm_access_token;
                dispatch({
                    type: GET_TOKEN,
                    payload: {
                        token: response.data.adm_access_token,
                    },
                });
            }
            return config;
        },
        (error) => {
            axios.get(`${url}/logout`).then(() => { });
            localStorage.removeItem("LoginSdm");
            dispatch({ type: ADMIN_LOGOUT });
            return Promise.reject(error);
        }
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const onSubmit = async (data) => {
        try {
            await axiosJWT.patch(
                `${url}/editPeserta/${pesertaId}`,
                data,
                {
                    headers: {
                        Authorization: tokenJwt
                    }
                }
            )
            navigate(`/${Directory}/detail_event/${eventId}`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const getDataPeserta = async () => {
            try {
                const { data } = await axiosJWT.get(`${url}/peserta/${pesertaId}`, {
                    headers: {
                        Authorization: tokenJwt
                    }
                })
                setDataPeserta(data)
                setIsLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        getDataPeserta()
    }, [])

    useEffect(() => {
        reset({
            nisn: dataPeserta.nisn,
            nama: dataPeserta.nama,
            email: dataPeserta.email,
            no_hp: dataPeserta.no_hp
        })
    }, [dataPeserta])



    return (
        <>
            <NavbarAdmin />
            {
                isLoading && (
                    <div
                        style={{
                            height: "80vh",
                            position: "relative",
                            fontSize: "30px",
                            fontWeight: "bolder",
                        }}
                    >
                        <div className="text-center text-danger vertical-center">
                            <div
                                className="spinner-border"
                                role="status"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                }}
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                            <div>Loading...</div>
                        </div>
                    </div>
                )
            }
            {
                !isLoading && (
                    <div className="container d-flex justify-content-center min-vh-100 align-items-center" >
                        <form onSubmit={handleSubmit(onSubmit)} className="row g-3 col-md-6 card p-5 m-5">
                            <div className="mb-2">
                                <label htmlFor="formGroupExampleInput" className="form-label">
                                    Nisn
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder="Masukan Nisn"
                                    {...register("nisn", {
                                        required: true,
                                    })}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="formGroupExampleInput" className="form-label">
                                    Nama Lengkap
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder="Nama Lengkap"
                                    {...register("nama", {
                                        required: true,
                                    })}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="formGroupExampleInput" className="form-label">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder="Email"
                                    {...register("email", {
                                        required: true,
                                    })}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="formGroupExampleInput" className="form-label">
                                    No Handphone
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="formGroupExampleInput"
                                    placeholder="No Handphone"
                                    {...register("no_hp", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.nisn?.type === "required" && (
                                <p
                                    role="alert"
                                    style={{ color: "red" }}
                                >
                                    Nisn tidak boleh kosong
                                </p>
                            )}
                            {errors.nama?.type === "required" && (
                                <p
                                    role="alert"
                                    style={{ color: "red" }}
                                >
                                    Nama tidak boleh kosong
                                </p>
                            )}
                            {errors.email?.type === "required" && (
                                <p
                                    role="alert"
                                    style={{ color: "red" }}
                                >
                                    Email tidak boleh kosong
                                </p>
                            )}
                            {errors.no_hp?.type === "required" && (
                                <p
                                    role="alert"
                                    style={{ color: "red" }}
                                >
                                    No Handphone tidak boleh kosong
                                </p>
                            )}
                            <button className="btn btn-danger">Update</button>
                        </form>
                    </div>
                )
            }
        </>
    )
}

export default EditPeserta